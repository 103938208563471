<template>
    <div class="container-page">
      <!-- banner -->
      <header-component-index :bannerImg="downloadBannerImg">
        <div class="download-slogan">
          <div class="download-slogan-info">
            <div class="txt animate__animated animate__bounceInDown">基于云服务器设计，集成管理</div>
            <div class="tit animate__animated animate__fadeInLeft">智能酒店一站式 管理系统</div>
            <div class="prompt animate__animated animate__bounceInDown animate__delay-1s">支持全系统下载 Android / iOS / Windows</div>
            <div class="terminal-list animate__animated animate__bounceInUp animate__delay-1s">
              <div class="grid" v-for="(item,index) of terminalListTemp" :key="index">
                <img class="ico" :src="item.img">
                <p class="txt">{{item.name}}</p>
                <a :href="item.link" target="_blank" title="" v-if="item.link">立即下载</a>
                <img :src="item.qrCode" v-if="item.qrCode" class="qr-code">
              </div>
            </div>
          </div>
          <div class="img animate__animated animate__fadeInRight">
            <img :src="downloadSloganImg">
          </div>
        </div>
      </header-component-index>

      <!-- 系统产品 -->
      <system-product-component-index :from="'download'"></system-product-component-index>


      <!-- 多端同步 -->
      <div class="section-page terminal-sync">
        <div class="container">
          <h3 class="h3">手机、电脑多端同步，轻松管理</h3>


          <div class="terminal-sync-info">
            <img :src="syncImg" class="sync-img">
            <div class="terminal-list">
              <div class="grid" v-for="(item,index) of terminalListTemp" :key="index">
                <img :src="item.imgGrey">
                <p class="txt">{{item.name}}</p>
                <a :href="item.link" target="_blank" title="" v-if="item.link">立即下载</a>
              </div>
            </div>
          </div>

          <p class="sub-tit">下载手机端与电脑端，多端同步，轻松管理</p>
        </div>
      </div>

      <!-- 合作与了解 -->
      <contact-component-index></contact-component-index>

      <!-- 底部 -->
      <footer-component-index></footer-component-index>
    </div>
</template>

<script>

  // assets
  import downloadBannerImg from '@/assets/banner-download.png'
  import downloadSloganImg from '@/assets/download-slogan.png'
  import syncImg from '@/assets/sync.png'

    // components
    import SystemProductComponentIndex from "../components/systemProduct";
    import ContactComponentIndex from "../components/contact";
    import FooterComponentIndex from "../components/footer";
    import HeaderComponentIndex from "../components/header";

    // api
    import {
      listHardwareVersion
    } from '@/module/common.js'

    export default {
        name: "downloadPageIndex",
        components: {
          HeaderComponentIndex,
          FooterComponentIndex,
          ContactComponentIndex,
          SystemProductComponentIndex

        },
        data() {
          return {
            downloadBannerImg,downloadSloganImg,
            syncImg,
            terminalListTemp:[]

          }
        },
        mounted() {

          this.terminalListTemp = this.$website.terminalList

          //  获取windows终端接口
          this.listHardwareVersionInit()

        },
      methods: {
          //  获取windows终端接口
          listHardwareVersionInit(){
            let _self = this
            listHardwareVersion().then(res=>{
              if(res.dataObj){
                _self.terminalListTemp[2].link = res.dataObj[0].appPath
              }
            }).catch(err=>{
              console.error(err.dataMsg)
            })
          }
        }
        //
    }
</script>

<style lang="scss" scoped>
  .download-slogan{
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    .download-slogan-info{
      display: flex;
      flex-direction: column;
      text-align: left;
      .txt{
        margin-top: 40px;
        font-size: 26px;
        color: #fff;
      }
      .tit{
        margin-top: 20px;
        font-size: 60px;
        color: #fff;
      }
      .prompt{
        margin-top: 30px;
        font-size: 21px;
        color: #FFF
      }
      .terminal-list{
        margin-top: 70px;
        display: flex;
        align-items: center;
        text-align: center;
        .grid{
          margin-right: 35px;
          position: relative;
          &:hover{
            .qr-code{
              display: block;
            }
          }
          .ico{
            width: 48px;
            height: 48px;
          }
          .qr-code{
            display: none;
            bottom: 45px;
            left: -26px;
            position: absolute;
            width: 150px;
            height: 150px;
          }
          .txt{
            margin-top: 5px;
            color: #fff;
            font-size: 16px;
          }
          a{
            margin-top: 10px;
            display: inline-block;
            text-decoration: none;
            width: 99px;
            height: 38px;
            line-height: 38px;
            background: #FFFFFF;
            border-radius: 6px;
            border: 2px solid #3D67F1;
            font-size: 16px;
            color: #3D67F1;
          }
        }
      }
    }
    .img{

    }
  }


  .terminal-sync{
      .terminal-sync-info{
        margin-top: 70px;
        .sync-img{
          width: 100%;
        }
        .terminal-list{
          margin-top: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          .grid{
            margin-left: 70px;
            img{
              width: 48px;
              height: 48px;
            }
            .txt{
              margin-top: 5px;
              color: #868693;
              font-size: 16px;
            }
            a{
              margin-top: 10px;
              display: inline-block;
              text-decoration: none;
              width: 99px;
              height: 38px;
              line-height: 38px;
              background: #FFFFFF;
              border-radius: 6px;
              border: 2px solid #3D67F1;
              font-size: 16px;
              color: #3D67F1;
            }
          }
        }
      }
  }


  @media screen and (min-width: 768px) and (max-width: 1080px) {
    .download-slogan{
      padding-top: 40px;
      align-items: center;
      .download-slogan-info{
        width: 60%;
        .txt{
          font-size: 20px;
          margin-top: 20px;
        }
        .tit{
          margin-top: 30px;
          font-size: 40px;
        }
      }
      .img{
        width: 40%;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .download-slogan{
      padding-top: 20px;
      align-items: center;
      .download-slogan-info{
        width: 100%;
        .txt{
          font-size: 14px;
          margin-top: 10px;
        }
        .tit{
          margin-top: 20px;
          font-size: 24px;
        }
        .prompt{
          font-size: 14px;
          margin-top: 20px;
        }
        .terminal-list{
          .grid{
            transform: scale(.9);
            margin-right: 0;
          }
        }
      }
      .img{
        display: none;
      }
    }



    .terminal-sync{
      .terminal-sync-info{
        .terminal-list{
          margin-top: 40px;
          .grid{
            width: 32.33%;
            margin-left:1%;
            transform: scale(.9);
          }
        }
      }
    }

  }
</style>
